.App {
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, rgba(249,252,249,1) 55%, #88c8b5 100%);
}
.WelcomeForm{
  text-align: center;
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(180deg, rgba(249,252,249,1) 55%, #F2FDF5 100%);
  
}

#splash {
  text-align: center;
  display: grid;
  grid-template-rows: 1fr auto 10% auto;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-color: #F5F5F5;
  
}

#logoIcon {
  width: 8.5vw;
  z-index: 0;
  border: 2px;
  display: none 
}
#big_square{
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%,-50%)  rotate(45deg); 
  height: calc(18vw * 1.5);
  width: calc(18vw * 1.5);
  border: 2px;
  
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: px;
  
}

.small_square{
  width: 100%;
  height: 100%;
  background-color: #46907e;
  display: block;
  float:left;
  animation: animate 1.8s linear infinite;
}
.small_square:nth-child(4){
    animation-delay: 0.9s;

} .small_square:nth-child(3),
  .small_square:nth-child(8){
    animation-delay: 0.75s;

} .small_square:nth-child(2),
  .small_square:nth-child(7),
  .small_square:nth-child(12){
    animation-delay: 0.6s;

} .small_square:nth-child(1),
  .small_square:nth-child(6),
  .small_square:nth-child(11),
  .small_square:nth-child(16){
    animation-delay: 0.45s;

} .small_square:nth-child(5),
  .small_square:nth-child(10),
  .small_square:nth-child(15){
    animation-delay: 0.3s;

} .small_square:nth-child(9),
  .small_square:nth-child(14){
    animation-delay: 0.15s;

} .small_square:nth-child(13){
    animation-delay: 0s;
}

@keyframes animate {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0);
  }50% {
    transform: scale(0);
  }75% {
    transform: scale(1);
  }100% {
    transform: scale(1);
  }
}

  
#splash_name{
  font-weight: 500;
  font-size: 4vw;
  color: #DC5E28;
  margin: 10px;
  
}


#logo {
  width: 250px;
  padding: 20px;
}


h2 {
  margin: 10px;
  font-size:larger
}

h3 {
  margin: 0;
  font-weight:normal;
  padding: 2px
}


.pageContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  overflow-y: scroll;
  padding-bottom: 80px;
}

.nav {
  position: fixed;
  bottom: 20px;
  color: white;
  background: #2D695B;
  width: 140px;
  border-radius: 15px;
  height: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
}

.container {
  display: grid;
  grid-template-rows: auto / 1fr;
  width: 75%;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
}

.container header {
  color: white;
  background: #2D695B;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

body {
  background: #88c8b5;
  overflow: hidden;
}

.homeButton {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: white;
  position: fixed;
  left:50%;
  transform: translateX(-50%);
  top: -5px;
}

#filledLogo {
  height: 80%;
  position: relative;
  top:50%;
  transform: translateY(-50%)
}

.orangeBtn {
  text-decoration: none;
  color: white;
  background-color: #DC5E28;
  padding: 5px 15px 5px 15px;
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
}


h4 {
  
  padding: 2px;
  font-weight: bold;
}

a{
  color: #2D695B;
  text-align: right;
  font-size: 11px;
  font-weight: bold;
}


p {
  margin: 0
}

#regForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 65%;
}

#regForm input {
  display: block;
}

.icons {
  display: flex;
  gap:5px;
  justify-content: right;
}

.icons img {
  height:15px;
}

.icons button, .nav button,  .increaseButton, .decreaseButton {
  all: unset;
  cursor: pointer;
  height: 18px;
}

.editButtonWhite img, .saveButton img{
  height: 18px;
  position: relative;
  top: 50%;
  transform: translateY(-50%)
}

.editButtonWhite {
  position: fixed;
  left: 15%;
  top: 50%;
}

button.saveButton {
  position: fixed;
  right: 13%;
  height: 90%;
}

.variableRow, .fixedRow {
  display: grid;
  grid-template-columns: 40% 40% 20%;
  margin: 10px 20px 10px 20px;
}

.decreaseButton img, .increaseButton img {
  height: 90%;
  position: relative;
  top:20%
}

.variablevalue {
  display: grid;
  grid-template-columns: 10% 80% 10%;
}

.row {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 5px;
}

.homeButton img {
  position: fixed;
  height: 30px;
  top: 12%;
  left: 34%;
}

.tickIcon {
  position: fixed;
  right: 7%;
}

.tickIcon img {
  height: 30px
}

ul {
  padding-right: 40px;
}

.incomeForm {
  padding: 5px;
}